// login.js

import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import { auth, db, microsoftProvider } from "./firebase";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; // For default styles
import { FaTimes } from "react-icons/fa";
import { signOut } from "firebase/auth";
import { FaGoogle, FaMicrosoft } from "react-icons/fa"; // Import FaGoogle and FaMicrosoft

import { doc, getDoc, updateDoc, setDoc, Timestamp } from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  sendEmailVerification,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { NODE_ENV } from "./constants/config";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

const Login = ({ closeLogin }) => {
  const { user, userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signInError, setSignInError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [localSignedIn, setLocalSignedIn] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  }); // Added state for password validations
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");
  // console.log("source", source);

  const [isExtensionFlow, setIsExtensionFlow] = useState(false);

  useEffect(() => {
    if (user) {
      setLocalSignedIn(true);
    }
  }, [user]);

  useEffect(() => {
    if (source === "extensionUserLogin") {
      setIsExtensionFlow(true);
    }
  }, [source]);

  useEffect(() => {
    const handleExtensionSuccess = (event) => {
      // Security check
      if (
        event.origin !== "https://www.kimavi.com" &&
        event.origin !== "http://localhost:3000"
      ) {
        return;
      }

      if (event.data.type === "extensionUserLoginSuccess") {
        console.log(
          "React: extensionUserLoginSuccess data =>",
          event.data.data
        );
        setShowSuccessMessage(true); // Show success message instead of toast
        if (closeLogin) {
          closeLogin();
        }
      } else if (event.data.type === "extensionUserLoginFailure") {
        console.error("React: extensionUserLoginFailure =>", event.data.error);
        toast.error("Extension verification failed");
      }
    };

    window.addEventListener("message", handleExtensionSuccess);
    return () => window.removeEventListener("message", handleExtensionSuccess);
  }, [closeLogin]);

  useEffect(() => {
    if (userEmail && isPaid !== undefined && idToken) {
      const postSignInMessage = () => {
        if (isExtensionFlow) {
          if (!idToken) {
            user?.getIdToken(true).then((newToken) => {
              window.postMessage(
                {
                  type: "extensionUserLogin",
                  data: {
                    email: userEmail,
                    token: newToken,
                    status: isPaid ? "paid" : "unpaid",
                  },
                },
                "*"
              );
            });
          } else {
            window.postMessage(
              {
                type: "extensionUserLogin",
                data: {
                  email: userEmail,
                  token: idToken,
                  status: isPaid ? "paid" : "unpaid",
                },
              },
              "*"
            );
          }
          return;
        }

        const isLoginPage = window.location.pathname === "/login";
        const isCorrectOrigin =
          window.location.origin === "https://www.kimavi.com" ||
          window.location.origin === "http://localhost:3000";

        // Regular sign-in message for same-origin usage
        if (isLoginPage && isCorrectOrigin) {
          window.postMessage(
            {
              type: "signInMessage",
              data: {
                email: userEmail,
                status: isPaid ? "paid" : "unpaid",
                idToken: idToken,
              },
            },
            window.location.origin
          );
        }
      };

      postSignInMessage();
    }
  }, [userEmail, isPaid, idToken, isExtensionFlow, user]);

  const actionCodeSettings = {
    url: window.location.origin + "/login",
    handleCodeInApp: true,
  };

  const verifyRecaptcha = async (token) => {
    const apiBaseUrl =
      NODE_ENV === "development1"
        ? "http://127.0.0.1:5001/json-storage-bed47/us-central1/api"
        : "https://us-central1-json-storage-bed47.cloudfunctions.net/api";

    try {
      const response = await fetch(`${apiBaseUrl}/verifyRecaptcha`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      const responseData = await response.json();

      if (responseData.success) {
        // toast.success("Welcome to Kimavi.....");
        return responseData;
      } else {
        toast.error("reCAPTCHA verification failed.");
        return { success: false };
      }
    } catch (error) {
      console.error("Error verifying reCAPTCHA", error);
      return { success: false };
    }
  };

  const updateFirestoreUser = async (user) => {
    try {
      // Check both collections simultaneously
      const [userDocSnapshot, userNewDocSnapshot] = await Promise.all([
        getDoc(doc(db, "users", user.email)),
        getDoc(doc(db, "usersNew", user.uid)),
      ]);

      // Prepare common user data
      const userData = {
        email: user.email,
        uid: user.uid,
        timeLogIn: Timestamp.now(),
        displayName: user.displayName,
        photoURL: user.photoURL,
      };

      // Update or create in users collection
      if (userDocSnapshot.exists()) {
        const updateData = {
          timeLogIn: Timestamp.now(),
        };

        if (
          user.displayName &&
          user.displayName !== userDocSnapshot.data().displayName
        ) {
          updateData.displayName = user.displayName;
        }
        if (
          user.photoURL &&
          user.photoURL !== userDocSnapshot.data().photoURL
        ) {
          updateData.photoURL = user.photoURL;
        }

        await updateDoc(doc(db, "users", user.email), updateData);
      } else {
        await setDoc(doc(db, "users", user.email), userData);
      }

      // Update or create in usersNew collection
      if (userNewDocSnapshot.exists()) {
        const updateDataNew = {
          timeLogIn: Timestamp.now(),
        };

        if (
          user.displayName &&
          user.displayName !== userNewDocSnapshot.data().displayName
        ) {
          updateDataNew.displayName = user.displayName;
        }
        if (
          user.photoURL &&
          user.photoURL !== userNewDocSnapshot.data().photoURL
        ) {
          updateDataNew.photoURL = user.photoURL;
        }

        await updateDoc(doc(db, "usersNew", user.uid), updateDataNew);
      } else {
        await setDoc(doc(db, "usersNew", user.uid), userData);
      }

      redirectAfterLogin();
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          updateFirestoreUser(result.user);

          toast.success("Successfully signed in!");
          redirectAfterLogin();
        })
        .catch((error) => {
          console.error("Error during email link sign-in", error);
        });
    }
  }, []);

  const handleSendSignInLink = async () => {
    if (!email) {
      setEmailError("Email is required.");
      toast.error("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);

    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setEmailError("Check your email for the sign-in link.");
        setTimeout(() => setEmailError(""), 3000);
        toast.info("Check your email for the sign-in link!");
      })
      .catch((error) => {
        console.error("Error sending email sign-in link", error);

        switch (error.code) {
          case "auth/operation-not-allowed":
            toast.error(
              "Email link sign-in is not enabled. Please contact support."
            );
            break;
          default:
            toast.error("Error sending sign-in link. Please try again later.");
            break;
        }
      });
  };

  const signInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        toast.success("Welcome to Kimavi!");
        updateFirestoreUser(user);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error signing in with Google. Please try again.");
      });
  };

  const signInWithMicrosoft = () => {
    const auth = getAuth();

    signInWithPopup(auth, microsoftProvider)
      .then((result) => {
        const user = result.user;
        toast.success("Welcome to Kimavi!");
        updateFirestoreUser(user);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error signing in with Microsoft. Please try again.");
      });
  };

  const handleForgotPassword = () => {
    if (!email) {
      setResetPasswordError("Please enter your email address.");
      return;
    }
    if (!emailRegex.test(email)) {
      setResetPasswordError("Invalid email format.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetPasswordSuccess("Password reset email sent. Check your inbox.");
        setTimeout(() => setResetPasswordSuccess(""), 5000);
        toast.info("Password reset email sent. Check your inbox.");
      })
      .catch((error) => {
        setResetPasswordError("Error sending password reset email.");
        setTimeout(() => setResetPasswordError(""), 3000);
        console.error(error);
        toast.error("Error sending password reset email.");
      });
  };

  const signInWithEmail = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (!email || !password) {
      setEmailError("Please enter both email and password.");
      setTimeout(() => setEmailError(""), 3000);
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);
    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = response.user;
        if (!user.emailVerified && user.email !== "kalpittest@test.com") {
          toast.info("Please verify your email to continue using Kimavi.");
          toast.info("For help please email us at admin@kimavi.com.");
          auth.signOut();
          return;
        }
        toast.success("Successfully signed in!");
        updateFirestoreUser(user);
      })
      .catch((error) => {
        setSignInError("Incorrect email or password.");
        setTimeout(() => setSignInError(""), 3000);
        console.error(error);
        toast.error("Incorrect email or password.");
      });
  };

  const checkPassword = (password) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[^A-Za-z0-9]/.test(password),
    };
    setPasswordValidations(validations);
  };

  const signUpWithEmail = async (e) => {
    e.preventDefault();

    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (!email || !password) {
      setEmailError("Please enter both email and password.");
      setTimeout(() => setEmailError(""), 3000);
      return;
    }

    const allValid = Object.values(passwordValidations).every((value) => value);
    if (!allValid) {
      setPasswordError("Password does not meet all requirements.");
      toast.error("Password does not meet all requirements.");
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);
    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = response.user;
        sendEmailVerification(user)
          .then(() => {
            toast.info(
              "Please verify your email. A verification link has been sent to your inbox."
            );
          })
          .catch((error) => {
            console.error(error);
            setSignInError(
              "Error sending verification email. Please try again."
            );
            setTimeout(() => setSignInError(""), 3000);
          });
        toast.info(
          "Registration successful. Please verify your email to continue."
        );
        auth.signOut();
        if (closeLogin) {
          closeLogin();
        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            setEmailError("This email address is already in use.");
            toast.info("Please sign in instead.");
            break;
          case "auth/invalid-email":
            setEmailError("The email address is not valid.");
            toast.info("Please enter a valid email address.");
            break;
          case "auth/operation-not-allowed":
            setSignInError("Email/password sign up is not enabled.");
            toast.info("Please sign in with Google instead.");
            break;
          case "auth/weak-password":
            setPasswordError(
              "Password is not strong enough. It should meet all the requirements."
            );
            toast.info("Please enter a stronger password.");
            break;
          default:
            setSignInError("Error during sign up. Please try again.");
            console.error(error);
            break;
        }

        setTimeout(() => {
          setEmailError("");
          setPasswordError("");
          setSignInError("");
        }, 3000);
      });
  };

  const redirectAfterLogin = () => {
    if (isExtensionFlow) {
      toast.success("Successfully connected to Kimavi Browser Extension!");
      if (closeLogin) {
        setTimeout(() => closeLogin(), 2000);
      }
      return;
    }

    if (source === "create-chatbot") {
      // If source is create-chatbot, go directly without reload
      toast("Welcome - Please Wait!");
      if (closeLogin) {
        closeLogin();
      }
      navigate("/create-chatbot");
      return;
    } else if (source === "extension" || source === "user") {
      // If source is extension or user, show toast and then reload current page
      toast("Welcome - Please Wait!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    } else {
      // For other sources, navigate accordingly after showing toast
      toast("Welcome - Please Wait!");
      if (source) {
        window.location.reload();
      } else {
        window.location.reload();
      }

      if (closeLogin) {
        closeLogin();
      }
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setLocalSignedIn(false);
        setShowSuccessMessage(false);
        toast.success("You have been signed out successfully.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
        toast.error("An error occurred while signing out.");
      });
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="relative max-w-md w-full mx-4">
        {/* Enhanced glowing background effect from HomeLayout */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-purple-500/30 blur-xl rounded-3xl"></div>
        <div className="absolute inset-0 bg-gradient-to-tr from-blue-400/20 via-purple-500/20 to-pink-500/20 animate-gradient-xy blur-lg rounded-3xl"></div>

        {/* Main content container */}
        <div className="relative bg-gray-900/80 backdrop-blur-sm rounded-2xl p-6 shadow-2xl border border-white/10">
          {closeLogin && (
            <button
              className="absolute top-4 right-4 text-gray-300 hover:text-blue-400 transition-colors"
              onClick={closeLogin}
            >
              <FaTimes />
            </button>
          )}

          {showSuccessMessage ? (
            <div className="text-center p-6">
              <div className="text-blue-400 text-xl font-bold mb-4">
                Successfully signed in!
              </div>
              <p className="text-gray-300">You can close this tab...</p>
            </div>
          ) : (
            <>
              {isExtensionFlow && (
                <div className="mb-4 p-3 bg-blue-500/20 rounded-lg text-blue-300 text-center">
                  <p className="text-sm">
                    Connecting to Kimavi Browser Extension
                  </p>
                  {signInError && (
                    <button
                      onClick={() => {
                        // Retry extension verification by posting message again
                        if (userEmail && isPaid !== undefined && idToken) {
                          window.postMessage(
                            {
                              type: "extensionUserLogin",
                              data: {
                                email: userEmail,
                                token: idToken,
                                status: isPaid ? "paid" : "unpaid",
                              },
                            },
                            "*"
                          );
                        }
                        setSignInError(""); // Clear error state
                      }}
                      className="mt-2 px-4 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm transition-colors"
                    >
                      Retry Connection
                    </button>
                  )}
                </div>
              )}

              {!localSignedIn && (
                <div className="text-white">
                  <div className="text-center text-2xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                    Please Sign In
                  </div>

                  <Tabs>
                    <TabList className="flex border-b border-gray-600/30">
                      <Tab className="flex-1 px-4 py-2 text-gray-300 hover:text-blue-400 cursor-pointer">
                        Sign with SSO
                      </Tab>
                      {/* <Tab className="flex-1 px-4 py-2 text-gray-300 hover:text-blue-400 cursor-pointer">
                        Sign In
                      </Tab>
                      <Tab className="flex-1 px-4 py-2 text-gray-300 hover:text-blue-400 cursor-pointer">
                        Sign Up
                      </Tab> */}
                    </TabList>

                    <TabPanel>
                      <div className="mt-4 mb-4 text-center">
                        <button
                          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-4 rounded-xl hover:shadow-blue-500/25 transition-all duration-300 inline-flex items-center mb-3 w-full justify-center"
                          type="button"
                          onClick={signInWithGoogle}
                        >
                          <FaGoogle className="mr-2" /> Sign In with Google
                        </button>
                        <button
                          className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-2 px-4 rounded-xl hover:shadow-blue-500/25 transition-all duration-300 inline-flex items-center w-full justify-center"
                          type="button"
                          onClick={signInWithMicrosoft}
                        >
                          <FaMicrosoft className="mr-2" /> Sign In with
                          Microsoft
                        </button>
                      </div>
                    </TabPanel>

                    {/* <TabPanel>
                      <form onSubmit={signInWithEmail}>
                        <div className="mb-4 text-left">
                          <label className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
                            Email
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="bg-gray-800/50 border border-gray-700 rounded-lg w-full py-2 px-3 text-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                          />
                          {emailError && (
                            <p className="text-red-500 text-xs italic">
                              {emailError}
                            </p>
                          )}
                        </div>
                        <div className="mb-6 text-left">
                          <label className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
                            Password
                          </label>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="bg-gray-800/50 border border-gray-700 rounded-lg w-full py-2 px-3 text-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                          />
                          {passwordError && (
                            <p className="text-red-500 text-xs italic">
                              {passwordError}
                            </p>
                          )}
                        </div>
                        {signInError && (
                          <p className="text-red-500 text-xs italic">
                            {signInError}
                          </p>
                        )}
                        <div className="flex items-center justify-between">
                          <button
                            type="submit"
                            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-4 rounded-xl hover:shadow-blue-500/25 transition-all duration-300"
                          >
                            Sign In
                          </button>
                          <button
                            type="button"
                            onClick={handleForgotPassword}
                            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                          >
                            Forgot Password?
                          </button>
                        </div>
                        {resetPasswordError && (
                          <p className="text-red-500 text-xs italic mt-4">
                            {resetPasswordError}
                          </p>
                        )}
                        {resetPasswordSuccess && (
                          <p className="text-green-500 text-xs italic mt-4">
                            {resetPasswordSuccess}
                          </p>
                        )}
                        <div className="mt-4">
                          <ReCAPTCHA
                            sitekey="6LeFwlspAAAAAFbquxEEqpVkTJziaM6VZmyyizrk"
                            onChange={(token) => setRecaptchaToken(token)}
                          />
                        </div>
                        <hr className="my-4" />
                        <p className="text-lg text-gray-700 bg-gray-100 p-4 rounded-md shadow-sm">
                          Email verification required. Please check your email
                          (including the junk/spam folder) for verification.
                          Email will come from our firebase servers:{" "}
                          <a
                            href="https://json-storage-bed47.firebaseapp.com"
                            className="text-blue-500 hover:underline"
                          >
                            json-storage-bed47.firebaseapp.com
                          </a>
                        </p>
                      </form>
                    </TabPanel>

                    <TabPanel>
                      <form onSubmit={signUpWithEmail}>
                        <div className="mb-4 text-left">
                          <label className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
                            Email
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="bg-gray-800/50 border border-gray-700 rounded-lg w-full py-2 px-3 text-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                          />
                          {emailError && (
                            <p className="text-red-500 text-xs italic">
                              {emailError}
                            </p>
                          )}
                        </div>
                        <div className="mb-6 text-left">
                          <label className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
                            Password
                          </label>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              checkPassword(e.target.value); // Check password rules
                            }}
                            className="bg-gray-800/50 border border-gray-700 rounded-lg w-full py-2 px-3 text-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                          />
                          {passwordError && (
                            <p className="text-red-500 text-xs italic">
                              {passwordError}
                            </p>
                          )}
                          <div className="mt-2">
                            <p
                              className={
                                passwordValidations.length
                                  ? "text-green-500 text-xs"
                                  : "text-red-500 text-xs"
                              }
                            >
                              • At least 8 characters
                            </p>
                            <p
                              className={
                                passwordValidations.uppercase
                                  ? "text-green-500 text-xs"
                                  : "text-red-500 text-xs"
                              }
                            >
                              • At least one uppercase letter
                            </p>
                            <p
                              className={
                                passwordValidations.lowercase
                                  ? "text-green-500 text-xs"
                                  : "text-red-500 text-xs"
                              }
                            >
                              • At least one lowercase letter
                            </p>
                            <p
                              className={
                                passwordValidations.number
                                  ? "text-green-500 text-xs"
                                  : "text-red-500 text-xs"
                              }
                            >
                              • At least one number
                            </p>
                            <p
                              className={
                                passwordValidations.specialChar
                                  ? "text-green-500 text-xs"
                                  : "text-red-500 text-xs"
                              }
                            >
                              • At least one special character
                            </p>
                          </div>
                        </div>
                        {signInError && (
                          <p className="text-red-500 text-xs italic">
                            {signInError}
                          </p>
                        )}
                        <div className="flex items-center justify-between">
                          <button
                            type="submit"
                            disabled={
                              !Object.values(passwordValidations).every(
                                (v) => v
                              ) || !email
                            }
                            className={`${
                              Object.values(passwordValidations).every(
                                (v) => v
                              ) && email
                                ? "bg-gradient-to-r from-blue-500 to-purple-600"
                                : "bg-gray-400 cursor-not-allowed"
                            } text-white font-bold py-2 px-4 rounded-xl hover:shadow-blue-500/25 transition-all duration-300`}
                          >
                            Sign Up
                          </button>
                        </div>
                        <div className="mt-4">
                          <ReCAPTCHA
                            sitekey="6LeFwlspAAAAAFbquxEEqpVkTJziaM6VZmyyizrk"
                            onChange={(token) => setRecaptchaToken(token)}
                          />
                        </div>
                        <hr className="my-4" />
                        <p className="text-lg text-gray-700 bg-gray-100 p-4 rounded-md shadow-sm">
                          Email verification required. Please check your email
                          (including the junk/spam folder) for verification.
                          Email will come from our firebase servers.{" "}
                          <a
                            href="https://json-storage-bed47.firebaseapp.com"
                            className="text-blue-500 hover:underline"
                          >
                            json-storage-bed47.firebaseapp.com
                          </a>
                        </p>
                      </form>
                    </TabPanel> */}
                  </Tabs>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
