// HomeLayout.js

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaRocket, FaRobot, FaVideo, FaBookOpen, FaPlug } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import ChatBot from "./chatbot";
import { MdOndemandVideo } from "react-icons/md";
import knownQA from "./knownQA";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FiX, FiArrowRight } from "react-icons/fi";

export default function KimaviLanding() {
  const rotationItems = [
    {
      text: (
        <span className="flex items-center gap-2">
          <FaBookOpen className="text-2xl md:text-3xl text-blue-400" />
          <span>Interactive Guides</span>
        </span>
      ),
    },
    {
      text: (
        <span className="flex items-center gap-2">
          <FaRobot className="text-2xl md:text-3xl text-blue-400" />
          <span>Audio Instructions</span>
        </span>
      ),
    },
    {
      text: (
        <span className="flex items-center gap-2">
          <FaRocket className="text-2xl md:text-3xl text-blue-400" />
          <span>Walkthrough Demos</span>
        </span>
      ),
    },
    {
      text: (
        <span className="flex items-center gap-2">
          <MdOndemandVideo className="text-2xl md:text-3xl text-blue-400" />
          <span>Videos</span>
        </span>
      ),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [showFloatingChat, setShowFloatingChat] = useState(false);

  // Add state for acquisition announcement banner
  const [showBanner, setShowBanner] = useState(false);
  const [animationStep, setAnimationStep] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % rotationItems.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [rotationItems.length]);

  useEffect(() => {
    const handleScroll = () => {
      setShowFloatingChat(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Show banner after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBanner(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // Logo animation sequence for acquisition announcement
  useEffect(() => {
    if (showBanner) {
      // Step 1: Show the two original logos
      const step1Timer = setTimeout(() => {
        setAnimationStep(2);
      }, 2000);

      // Step 2: Move logos together
      const step2Timer = setTimeout(() => {
        setAnimationStep(3);
      }, 4000);

      return () => {
        clearTimeout(step1Timer);
        clearTimeout(step2Timer);
      };
    }
  }, [showBanner]);

  const toggleFAQ = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900 text-white">
      <AnimatePresence mode="wait">
        <motion.div
          key="home-layout-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10 animate-gradient-xy"
        ></motion.div>
      </AnimatePresence>

      {/* Acquisition Announcement Banner */}
      <AnimatePresence>
        {showBanner && (
          <motion.section
            className="bg-[#E7F8F0] py-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="max-w-6xl mx-auto px-4">
              <div className="flex justify-between items-center mb-6">
                <div className="flex-1 text-center">
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-3xl md:text-4xl lg:text-5xl font-bold text-[#131955]"
                  >
                    AuxoAI acquires Kimavi
                  </motion.h2>
                </div>
                <button
                  className="text-[#0CB765] text-2xl hover:text-[#0ca757] transition-colors absolute top-8 right-8"
                  onClick={() => setShowBanner(false)}
                >
                  <FiX />
                </button>
              </div>

              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.8 }}
                className="text-xl md:text-2xl text-gray-700 mb-6 text-center"
              >
                Advancing the Future of AI-Powered Agentic Workflows
              </motion.p>

              <div className="flex justify-center items-center h-[200px] relative mb-6">
                {/* Logo animation sequence */}
                <div className="flex justify-center items-center w-full">
                  {animationStep === 1 && (
                    <div className="flex flex-col md:flex-row justify-center items-center w-full">
                      <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className="md:mr-8 mb-4 md:mb-0 flex items-center"
                        style={{ height: "80px" }}
                      >
                        <img
                          src="/logo/auxoai.png"
                          alt="AuxoAI Logo"
                          className="h-auto w-auto max-h-full object-contain"
                          style={{ maxWidth: "180px" }}
                        />
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                        className="text-[#0CB765] text-4xl md:text-5xl font-bold mx-4 md:mx-8"
                      >
                        +
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className="md:ml-8 flex items-center"
                        style={{ height: "80px" }}
                      >
                        <img
                          src="/logo/kimavi.png"
                          alt="Kimavi Logo"
                          className="h-auto w-auto max-h-full object-contain"
                          style={{ maxWidth: "180px" }}
                        />
                      </motion.div>
                    </div>
                  )}

                  {animationStep === 2 && (
                    <div className="flex flex-col md:flex-row justify-center items-center">
                      <motion.div
                        initial={{ x: -100 }}
                        animate={{ x: -20 }}
                        transition={{ duration: 1 }}
                        className="md:mr-4 mb-4 md:mb-0 flex items-center"
                        style={{ height: "80px" }}
                      >
                        <img
                          src="/logo/auxoai.png"
                          alt="AuxoAI Logo"
                          className="h-auto w-auto max-h-full object-contain"
                          style={{ maxWidth: "180px" }}
                        />
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className="text-[#0CB765] text-4xl md:text-5xl font-bold mx-2 mb-4 md:mb-0"
                      >
                        +
                      </motion.div>

                      <motion.div
                        initial={{ x: 100 }}
                        animate={{ x: 20 }}
                        transition={{ duration: 1 }}
                        className="md:ml-4 flex items-center"
                        style={{ height: "80px" }}
                      >
                        <img
                          src="/logo/kimavi.png"
                          alt="Kimavi Logo"
                          className="h-auto w-auto max-h-full object-contain"
                          style={{ maxWidth: "180px" }}
                        />
                      </motion.div>
                    </div>
                  )}

                  {animationStep === 3 && (
                    <motion.div
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="flex items-center"
                      style={{ height: "90px" }}
                    >
                      <img
                        src="/logo/kimavi-by-auxoai_2x_dark.png"
                        alt="Kimavi by AuxoAI Logo"
                        className="h-auto w-auto max-h-full object-contain"
                        style={{ maxWidth: "220px" }}
                      />
                    </motion.div>
                  )}
                </div>
              </div>

              <div className="text-center">
                <a
                  href="https://www.auxoai.com/kimavi-acquisition"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-semibold shadow-lg transition-all duration-300 overflow-hidden group"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://www.auxoai.com/kimavi-acquisition",
                      "_blank"
                    );
                  }}
                >
                  <span className="relative z-10">
                    Learn More <FiArrowRight className="inline ml-2" />
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shine" />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur-xl" />
                </a>
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>

      <div className="relative container mx-auto px-4 py-12 min-h-screen flex items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <AnimatePresence mode="wait">
            <motion.div
              key="home-layout-content"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
              className="space-y-8"
            >
              <div className="flex justify-center w-full">
                <motion.span
                  className="px-6 py-3 rounded-full border border-blue-500/30 bg-blue-500/10 text-lg md:text-xl font-semibold cursor-pointer hover:bg-blue-500/20"
                  animate={{
                    scale: [1, 1.05, 1],
                    boxShadow: [
                      "0 0 0 rgba(59, 130, 246, 0)",
                      "0 0 20px rgba(59, 130, 246, 0.3)",
                      "0 0 0 rgba(59, 130, 246, 0)",
                    ],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                  onClick={() => navigate("/create-chatbot")}
                >
                  <span className="bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                    ChatGPT AI - For Your Business
                  </span>
                </motion.span>
              </div>

              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight m-4 p-4 text-center">
                <motion.span
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  className="block text-3xl md:text-4xl lg:text-5xl mb-2"
                >
                  Upgrade your chatbot to engage users with
                </motion.span>
                <motion.span
                  key={currentIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="inline-flex flex-col items-center gap-2 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
                >
                  <span className="text-2xl md:text-3xl lg:text-4xl p-2 md:p-4">
                    {rotationItems[currentIndex].icon}
                  </span>
                  <span className="text-3xl md:text-4xl lg:text-5xl pb-4">
                    {rotationItems[currentIndex].text}
                  </span>
                </motion.span>
              </h1>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="text-xl text-gray-300 leading-relaxed max-w-xl p-4 m-4 mx-auto text-center"
              >
                Harness the power of ChatGPT AI with rich-media features to
                create engaging, interactive experiences that boost user
                engagement by up to 300%.
              </motion.p>

              <div className="flex justify-center gap-4 m-4 p-4">
                <motion.button
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    scale: [1, 1.05, 1],
                    boxShadow: [
                      "0 0 0 rgba(59, 130, 246, 0)",
                      "0 0 30px rgba(59, 130, 246, 0.5)",
                      "0 0 0 rgba(59, 130, 246, 0)",
                    ],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  }}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0 0 40px rgba(59, 130, 246, 0.6)",
                  }}
                  whileTap={{ scale: 0.95 }}
                  className="relative px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg font-semibold shadow-lg transition-all duration-300 overflow-hidden group"
                  onClick={() => navigate("/create-chatbot")}
                >
                  <span className="relative z-10 animate-pulse">
                    Get Started For Free
                  </span>
                  <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shine" />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur-xl" />
                </motion.button>
              </div>
            </motion.div>
          </AnimatePresence>

          <AnimatePresence mode="wait">
            <motion.div
              key="home-layout-chatbot"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative"
            >
              <div className="flex justify-center">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                  className="w-[400px] h-[700px] rounded-xl bg-gray-900/90 shadow-2xl hover:shadow-blue-500/25 relative overflow-hidden"
                >
                  <ChatBot
                    modal={true}
                    isOpen={true}
                    iframeMode={false}
                    suggestionMode="demos"
                    welcomeMessageIndex={0}
                  />
                </motion.div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      <div className="bg-gradient-to-br from-gray-900 via-black to-gray-900 text-white px-4 py-24 min-h-screen">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Text content on the left */}
          <div className="space-y-4">
            <span className="inline-block bg-gray-800 text-sm px-4 py-1 rounded-full uppercase tracking-widest">
              Future of Chatbots
            </span>
            <h2 className="text-3xl md:text-4xl font-bold leading-tight space-y-4">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="py-8"
              >
                Just like <span className="text-blue-400">social media</span>,
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                chatbots are evolving
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="py-8"
              >
                beyond text-only interaction.
              </motion.div>
            </h2>
            <motion.p
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              className="text-lg text-gray-300"
            >
              Kimavi empowers your chatbot to respond to user questions with
              video, audio, embedded slides, and other rich media.
            </motion.p>
          </div>

          {/* Image on the right */}
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <img
              src="/images/robots.avif"
              alt="Robots"
              className="w-full h-auto rounded-xl shadow-lg"
            />
          </motion.div>
        </div>
      </div>
      <div className="py-6 bg-[#0E141B] text-white border-t border-gray-700">
        <div className="container mx-auto px-4 flex flex-wrap items-center justify-center gap-8 md:gap-6">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="flex items-center gap-2"
          >
            <FaPlug className="text-2xl text-blue-400" />
            <span className="text-lg md:text-xl uppercase tracking-widest">
              Integrations
            </span>
          </motion.div>

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            src="/images/intercom.avif"
            alt="Intercom"
            className="h-5 md:h-6"
          />

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            src="/images/drift.avif"
            alt="Drift"
            className="h-5 md:h-6"
          />

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            src="/images/tidio.avif"
            alt="Tidio"
            className="h-5 md:h-6"
          />

          <motion.img
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            src="/images/whatsapp.avif"
            alt="WhatsApp"
            className="h-5 md:h-6"
          />
        </div>
      </div>
      <section className="py-12 bg-[#0E141B] text-white">
        <div className="max-w-6xl mx-auto px-4 space-y-16">
          {/* Card 1: Text left, iFrame right */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center min-h-screen">
            <div className="space-y-6 text-center">
              <motion.h3
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-3xl font-semibold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
              >
                How-To Guides &amp; Interactive Demos
              </motion.h3>
              <motion.hr
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="border-gray-600 w-24 mx-auto"
              />
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="text-gray-300 leading-relaxed text-lg max-w-xl mx-auto"
              >
                Easily record product walk-throughs that guide users,
                step-by-step, to complete a task or to use a feature. Your
                chatbot will start answering questions using saved How-To
                content.
              </motion.p>
            </div>
            <div className="flex justify-center">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.5 }}
                className="w-[400px] h-[700px] rounded-xl bg-white/95 shadow-2xl hover:shadow-blue-500/25 relative overflow-hidden"
              >
                <ChatBot
                  modal={true}
                  isOpen={true}
                  iframeMode={false}
                  suggestionMode="random"
                  welcomeMessageIndex={1}
                />
              </motion.div>
            </div>
          </div>

          {/* Card 2: Text left, iFrame right */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center min-h-screen">
            <div className="space-y-6">
              <motion.h3
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-3xl font-semibold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
              >
                Instructional Video, Audio &amp; Animation
              </motion.h3>
              <motion.hr
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="border-gray-600 w-24 mx-auto"
              />
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="text-gray-300 leading-relaxed text-lg max-w-xl mx-auto"
              >
                When instructional video or audio offers the most helpful
                answer, Kimavi searches your media library for a response—or
                will use AI to generate video and audio on-the-fly.
              </motion.p>
            </div>
            <div className="flex justify-center">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.5 }}
                className="w-[400px] h-[700px] rounded-xl bg-gray-900/90 shadow-2xl hover:shadow-blue-500/25 relative overflow-hidden"
              >
                <ChatBot
                  modal={true}
                  isOpen={true}
                  iframeMode={false}
                  suggestionMode="random"
                  welcomeMessageIndex={2}
                />
              </motion.div>
            </div>
          </div>

          {/* Card 3: Text left, iFrame right */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center min-h-screen">
            <div className="space-y-6 text-center">
              <motion.h3
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-3xl font-semibold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
              >
                Smart Actions &amp; Lookups
              </motion.h3>
              <motion.hr
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="border-gray-600 w-24 mx-auto"
              />
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="text-gray-300 leading-relaxed text-lg max-w-xl mx-auto"
              >
                Instruct your chatbot to take actions by calling any API, such
                as adding a subscription plan, updating an email address, or
                finding a video on YouTube for display by your chatbot.
              </motion.p>
            </div>
            <div className="flex justify-center">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.5 }}
                className="w-[400px] h-[700px] rounded-xl bg-white/95 shadow-2xl hover:shadow-blue-500/25 relative overflow-hidden"
              >
                <ChatBot
                  modal={true}
                  isOpen={true}
                  iframeMode={false}
                  suggestionMode="random"
                  welcomeMessageIndex={0}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-gray-900 to-[#0E141B] min-h-screen">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">
            <span className="bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
              Frequently Asked Questions
            </span>
          </h2>

          <div className="max-w-4xl mx-auto space-y-4">
            {knownQA.slice(0, 10).map((item, index) => {
              const isOpen = openFaqIndex === index;
              return (
                <div
                  key={index}
                  className="rounded-lg shadow-lg overflow-hidden border border-gray-800 transition-all duration-300"
                >
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="w-full text-left px-6 py-4 focus:outline-none bg-gray-900/80 backdrop-blur-sm hover:bg-gray-800/80 transition-all duration-300 group"
                  >
                    <div className="flex justify-between items-center">
                      <h3 className="text-xl font-semibold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                        {item.question}
                      </h3>
                      <motion.span
                        animate={{ rotate: isOpen ? 180 : 0 }}
                        transition={{ duration: 0.3 }}
                        className="text-blue-400 font-bold"
                      >
                        {isOpen ? "-" : "+"}
                      </motion.span>
                    </div>
                  </button>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="w-full px-6 py-4 overflow-hidden"
                      >
                        <motion.div
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -20 }}
                          className="bg-gray-50 rounded-xl shadow-lg p-6 mx-auto max-w-3xl"
                        >
                          <div className="prose prose-lg text-gray-700 mx-auto">
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              components={{
                                a: ({ node, ...props }) => (
                                  <a
                                    {...props}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:text-purple-500 underline transition-colors duration-300"
                                  />
                                ),
                              }}
                            >
                              {item.answer}
                            </ReactMarkdown>
                          </div>
                        </motion.div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              );
            })}

            <div className="text-center mt-8">
              <Link
                to="/faq"
                className="inline-block px-8 py-4 rounded-lg font-semibold shadow-lg transition-all duration-200 bg-gradient-to-r from-blue-500 to-purple-600 hover:shadow-blue-500/25 text-white"
              >
                View All FAQs
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-[#0E141B] to-gray-900 min-h-screen">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-extrabold text-white mb-12"
          >
            TLDR
            <motion.span
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="block mt-4 text-3xl bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
            >
              We're building the future of human-computer interaction
            </motion.span>
          </motion.h2>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mx-auto max-w-screen-lg mb-12">
            {[
              {
                icon: <FaRobot className="text-3xl text-blue-400" />,
                title: "AI-Powered Assistance",
                description:
                  "Natural conversations with context-aware responses and rich media support",
              },
              {
                icon: <MdOndemandVideo className="text-3xl text-blue-400" />,
                title: "Visual Guidance",
                description:
                  "Interactive demos and video tutorials generated on-demand",
              },
              {
                icon: <FaRocket className="text-3xl text-blue-400" />,
                title: "Smart Automation",
                description:
                  "Tell your computer what to do and watch it happen automatically",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 + index * 0.2 }}
                className="bg-gray-900/80 backdrop-blur-sm border border-white/10 p-8 rounded-xl shadow-lg text-center hover:bg-gray-800/80 transition duration-200"
              >
                <div className="flex justify-center mb-4">{feature.icon}</div>
                <h3 className="text-2xl font-semibold text-blue-400 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>

          <div className="max-w-2xl mx-auto text-center">
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1.0 }}
              className="text-xl text-gray-300 mb-8"
            >
              We're focused on helping you build the best AI chatbot for your
              visitors and customers.
            </motion.p>
            <motion.a
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1.2 }}
              href="https://calendly.com/kalpit-kimavi"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-8 py-4 rounded-lg font-semibold shadow-lg transition-all duration-200 bg-gradient-to-r from-blue-500 to-purple-600 hover:shadow-blue-500/25"
            >
              Talk to a Human
            </motion.a>
          </div>
        </div>
      </section>

      <section className="py-12 bg-[#0E141B]">
        <div className="container mx-auto px-4">
          {/* Built By Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-sm text-gray-400 text-center space-y-4"
          >
            <div>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                Built by{" "}
              </motion.span>
              <motion.a
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.05, color: "#60A5FA" }}
                transition={{ duration: 0.3 }}
                href="https://www.linkedin.com/in/kalpitj/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300 underline"
              >
                Kalpit
              </motion.a>
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                {" "}
                - in San Jose, CA - With React, Firebase, OpenAI, Pinecone; LLM:
                gpt-4o-mini, Embedding model: text-embedding-3-small
              </motion.span>
            </div>
          </motion.div>

          {/* Links Section */}
          <div className="mt-8 text-center space-y-4">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <Link
                to="/privacy-security-cookie"
                className="text-gray-400 hover:text-blue-400 text-sm font-semibold block transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
              >
                Terms of Use: Privacy, Security, and Cookie Policy
              </Link>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              className="text-sm text-gray-400"
            >
              For Help and Support, please email{" "}
              <motion.a
                whileHover={{ scale: 1.05, color: "#60A5FA" }}
                transition={{ duration: 0.3 }}
                href="mailto:admin@kimavi.com"
                className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
              >
                admin@kimavi.com
              </motion.a>
            </motion.div>
          </div>

          {/* Talk to Human Button */}
        </div>
      </section>

      <div className="bottom-0 w-full">
        <AnimatePresence>
          {showFloatingChat && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
            >
              <ChatBot suggestionMode="default" welcomeMessageIndex={0} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
